@import "bootstrap/dist/css/bootstrap.min.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

body {
	overflow-x: hidden;
}

h5 {
	padding-bottom: 1rem;
	padding-left: 1.5rem;
	padding-top: 1.5rem;
}

.span-link:hover {
	color: #CDB974 !important;
}

.stagingEnviro{
border-color:red;
border-width: 5px;
border-style: solid;
}

.productionEnviro{
	
}