.layout-topbar {	
	background-color: #3C3C3C;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0 ,0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
	display: flex;
	height: 4rem;
	left: 0;
	padding: 0 2rem;
	position: fixed;
	top: 0;
	transition: left 0.2s;
	width: 100% !important;
	z-index: 997;
	justify-content: space-between;
}

.layout-topbar .layout-menu-button {
	margin-left: 0rem;
}

.layout-topbar .layout-topbar-button {
	align-items: center;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-flex;
	height: 3rem;
	justify-content: center;
	position: relative;
	transition: background-color 0.2s;
	width: 3rem;
}

.layout-topbar .layout-topbar-button:hover {
	background-color: #E9ECEF;
	color: #495057;
}

.layout-topbar .layout-topbar-button:focus, .layout-topbar .layout-topbar-logo:focus {
	box-shadow: 0 0 0 0.2rem #B7E0B8;
	outline: 0 none;
	outline-offset: 0;
	transition: box-shadow 0.2s;
}

.layout-topbar .layout-topbar-button i {
	font-size: 1.5rem;
}

.layout-topbar .layout-topbar-button span {
	font-size: 1rem;
	display: none;
}

.layout-topbar .layout-topbar-sectionId {
	display: flex;
	align-items: right;
	color: #FFFFFF;
	font-size: 1.5rem;
	font-weight: 500;
	width: 300px;
	margin-left: 2rem;
	vertical-align: middle;
	height:4rem;
	padding-top:1rem
}

.layout-topbar .layout-topbar-logo {
	display: flex;
	align-items: center;
	color: #FFFFFF;
	font-size: 1.5rem;
	font-weight: 500;
	width: 300px;
	margin-left: 2rem;
}

.layout-topbar .layout-topbar-logo img {
	height: 2.5rem;
	margin-right: .5rem;
}

.layout-topbar .layout-topbar-menu {
	margin: 0 0 0 auto;
	padding: 0;
	list-style: none;
	display: flex;
}

.layout-topbar .layout-topbar-menu .layout-topbar-button {
	margin-left: 1rem;
}

.layout-topbar .layout-topbar-menu-button {
	display: none;
}

.layout-topbar .layout-topbar-menu-button i {
	font-size: 1.25rem;
}

.p-menu.p-component {
	background-color: #FFFFFF;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
	height: calc(100vh - 8rem);
	left: 2rem;
	overflow-y: auto;
	padding: 0.5rem;
	position: fixed;
	top: 6rem;
	transition: left 0.2s, -webkit-transform 0.2s;
	transition: transform 0.2s, left 0.2s;
	transition: transform 0.2s, left 0.2s, -webkit-transform 0.2s;
	user-select: none;
	width: 250px;
	z-index: 999;
}

.p-menuitem-link.active *:not(.p-badge) {
	color: #CDB974 !important;
}